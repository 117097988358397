<template>
  <b-row>
    <b-col cols="12">
      <b-tabs
        content-class="mt-3"
        pills
      >
        <b-tab
          title="General"
          active
          pills
          :title-link-class="[bgTabsNavs]"
        >
          <client-call-log />
        </b-tab>
        <b-tab
          title="CEO calls"
          pills
          :title-link-class="[bgTabsNavs]"
        >
          <ceo-call-log />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>

import CeoCallLog from '@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue';
import ClientCallLog from '@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue';

export default {
  components: {
    CeoCallLog,
    ClientCallLog,
  },

  data() {
    return {
      generalTitle: 'client-general-call',
      generalTitleCeo: 'client-ceo-call',
    };
  },

  computed: {
    getGeneralCallsRoute() {
      return `${this.$route.matched[0].meta.moduleName}-${this.generalTitle}`;
    },

    getCeoCallRoute() {
      return `${this.$route.matched[0].meta.moduleName}-${this.generalTitleCeo}`;
    },
  },

  mounted() {
    console.log(this.getNameGeneral);
    console.log(this.getCeoCall);
  },
};
</script>
