<template>
  <div>
    <b-nav card-header pills class="mb-0">
      <b-nav-item
        @click="changeConversation(true)"
        exact
        :active="conversation"
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >CONVERSATION
      </b-nav-item>
      <b-nav-item
        @click="changeConversation(false)"
        exact
        exact-active-class="active"
        :active="!conversation"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >NO CONVERSATION
      </b-nav-item>
    </b-nav>
    <b-card no-body class="mb-0">
      <template #header v-if="withHeader">
        <b-card-title class="card-title-address">
          <div>Call Log</div>
        </b-card-title>
      </template>
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @onChangeCurrentPage="getClientLog"
        @reset-all-filters="resetAllFilters"
        @reload="getClientLog()"
        :double-paginate="doublePaginate"
      >
        <b-table
          id="rcTable"
          slot="table"
          ref="rcTable"
          :items="listCallRecording"
          :fields="fields"
          :filter="filter"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          :sticky-header="maxHeight"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(from)="data">
            <div class="d-flex">
              <b-avatar class="mr-1" variant="light-success">
                <feather-icon icon="PhoneOutgoingIcon" size="16" />
              </b-avatar>
              <div>
                <div>
                  {{ data.item.from_name }}
                </div>
                <b-badge variant="light-success">{{
                  data.item.from_number
                }}</b-badge>
              </div>
            </div>
          </template>

          <template #cell(to)="data">
            <div class="d-flex">
              <b-avatar
                class="mr-1"
                :variant="getCallStatus(data.item.status)[0]"
                v-b-tooltip.hover
                :title="data.item.status"
              >
                <feather-icon
                  :icon="getCallStatus(data.item.status)[1]"
                  size="16"
                />
              </b-avatar>
              <div>
                <div>
                  {{ data.item.to_name }}
                </div>
                <div>
                  <b-badge :variant="getCallStatus(data.item.status)[0]">{{
                    data.item.to_number
                  }}</b-badge>
                </div>
              </div>
            </div>
          </template>

          <template #cell(start_time)="data">
            <div>
              {{ data.item.start_time | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(status)="data">
            <div class="text-left">
              <div>{{ data.item.status }}</div>
              <div v-if="minFields">
                {{ data.item.start_time | myGlobalWithHour }}
              </div>
            </div>
          </template>

          <template #cell(record_url)="data">
            <div
              class="w-100 d-flex justify-content-around text-center"
              v-if="data.item.was_recording"
            >
              <div
                style="position: sticky; top: 0; z-index: 1"
                v-if="data.item.show_item && data.item.record_url"
              >
                <wave-surfer-player
                  style="height: 1.5rem"
                  :url="data.item.record_url"
                  :options="audioOptions"
                  :wave-surfer-id="`wave-surfer-${data.index}`"
                  :elapsed-time-width="50"
                  :file_name="data.item.original_id"
                  @loading="data.item.isLoading = true"
                  @ready="data.item.isLoading = false"
                  @WaveSurferEvents="load"
                  :download="false"
                />
              </div>
              <feather-icon
                v-else
                @click="data.item.show_item = !data.item.show_item"
                icon="LoaderIcon"
                class="text-primary cursor-pointer"
                v-b-tooltip.hover
                title="Load record"
              />
            </div>
          </template>
          <template #cell(duration)="data">
            <div class="w-100 d-flex justify-content-around text-center">
              {{ convertMSeconds(data.item.duration) }}
            </div>
          </template>

          <template #cell(actions)="data">
            <div
              v-if="data.item.hidden === 1"
              class="w-100 d-flex justify-content-around text-center text-warning"
            >
              <feather-icon
                v-if="!isResponsibleAccount"
                :id="data.index + 'tooltip'"
                icon="EyeOffIcon"
                class="cursor-pointer"
                @click="hiddenCall(data.item.call_id)"
              />
              <b-tooltip
                :target="data.index + 'tooltip'"
                triggers="hover"
                noninteractive
                variant="info"
                >Dont show this call</b-tooltip
              >
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
  </div>
</template>

<script>
import Filters from "@/views/commons/components/ring-central/call-log/data/FiltersClient.js";
import Fields from "@/views/commons/components/ring-central/call-log/data/FieldsClient.js";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import { mapGetters } from "vuex";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";

export default {
  name: "ClientCallLog",
  components: { WaveSurferPlayer },
  props: {
    client_account: {
      type: String || Number,
      required: false,
      default: null,
    },
    maxHeight: {
      type: String,
      default: "70vh",
      required: false,
    },
    doublePaginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    withHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    minFields: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Number...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      listCallRecording: [],
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      conversation: true,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fields() {
      let newFields = [...Fields];

      if (this.minFields) {
        newFields.splice(3, 1);
      }
      return newFields;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    changeConversation(value) {
      this.conversation = value;
      this.getClientLog();
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.getClientLog();
    },
    resetSearch() {
      this.searchInput = "";
      this.getClientLog();
    },
    async getClientLog() {
      try {
        this.addPreloader();
        const params = {
          lead_id: this.$route.params.id ? this.$route.params.id : null,
          client_account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.client_account,
          search_txt: "",
          per_page: 50,
          page: 1,
          date_from: null,
          date_to: null,
          status: null,
          hidden: null,
          module_id: null,
          ceo_calls: "c",
          is_conversation: this.conversation ? 1 : 0,
        };
        const { data } = await CallLogService.getCallLogsByLeadId(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.listCallRecording = data.data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
    getCallStatus(status) {
      if (status === "Accepted" || status === "Call connected") {
        return ["light-primary", "PhoneIncomingIcon"];
      }

      return ["light-danger", "PhoneMissedIcon"];
    },

    async hiddenCall(id) {
      const result = await this.showQuestionSwal(
        "Do you want to perform this action?",
        "This will hide the call for all users."
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            call_id: id,
          };
          const { data } = await CallLogService.hiddenCall(params);
          this.getClientLog();
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },

    isTicketCustomer() {
      return 1;
    },
  },
  created() {
    this.getClientLog();
  },
};
</script>

  <style scoped>
</style>